<template>
  <div>
    <b-container class="mt-3">
      <h3 class="mt-3">Lening</h3>
      <b-row>
        <b-col cols="12" lg="6" class="d-flex align-items-stretch" @click="selectContract('LinearLoanContract')">
          <div class="d-flex flex-column agreement-card p-2">
            <h3 class="mb-3">Lineaire lening</h3>
            <i class="mb-3">
              <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" y1="-2.18557e-08" x2="0.500002" y2="48" stroke="black" />
                <line x1="48" y1="48.5" y2="48.5" stroke="black" />
                <line x1="3.27038" y1="9.57941" x2="45.2704" y2="36.5794" stroke="black" />
              </svg>
            </i>
            <p>Bij een lineaire lening staat je aflossing vast. Daarentegen neemt je rente iedere betalingstermijn juist af.</p>
          </div>
        </b-col>

        <b-col cols="12" lg="6" class="d-flex align-items-stretch mt-1 mt-lg-0" @click="selectContract('AnnuityLoanContract')">
          <div class="d-flex flex-column agreement-card p-2">
            <h3 class="mb-3">Annuïteitenlening</h3>
            <i class="mb-3">
              <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0L0.500002 48" stroke="black" />
                <path d="M48 48.5H0" stroke="black" />
                <path d="M3 14.5251L7.83192 15.605C23.1451 19.0272 36.4965 28.3382 45 41.5251" stroke="black" />
                <path d="M3.5 6.5H45" stroke="black" />
              </svg>

            </i>
            <p>Bij een annuïtenlening zijn je maandlasten iedere maand hetzelfde. Naarmate je meer aflost, wordt het rente-deel van je maandlasten lager.</p>
          </div>
        </b-col>

        <b-col cols="12" lg="6" class="d-flex align-items-stretch mt-1" @click="selectContract('RepaymentFreeLoanContract')">
          <div class="d-flex flex-column agreement-card p-2">
            <h3 class="mb-3">Aflossingsvrije lening</h3>
            <i class="mb-3">
              <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0.377441L0.500002 48.3774" stroke="black" />
                <path d="M48 48.8774H0" stroke="black" />
                <path d="M4 42H40.2045" stroke="black" />
                <path d="M40 6V42.2045" stroke="black" />
              </svg>

            </i>
            <p>Bij een aflossingsvrije lening betaalt jouw familielid maandelijks rente, maar wordt er niet afgelost. Aan het einde van de periode dient het gehele bedrag afgelost te worden. <br /> <b>Let op:</b> deze leningsvorm is niet belastingaftrekbaar.</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  props: {

  },
  components: {

  },
  data() {
    return {
      selectedContract: null,
    }
  },
  methods: {
    selectContract(contractType) {
      this.selectedContract = contractType
      this.$emit('newContractSelected', contractType)
    },
  },
}
</script>

<style lang="scss" scoped>
.agreement-card {
  background-color: #F8F8F8;
  border: 1px solid transparent;
  border-radius: 6px;
}

.agreement-card:hover {
  border-color: var(--primary);
  background-color: #F5FDFF;
}
</style>

<template>
  <div>
    <FamilyImageBanner />
    <div v-if="contracts.length === 0">
      <section>
        <b-container class="mt-2">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <h1>Welkom bij Familiebank, <span style="color: #01a189;">{{ $auth.db.firstname }}</span></h1>
            <p>Kies een contract om te beginnen</p>
          </div>
          <b-row>
            <b-modal id="new-gift" size="lg" no-enforce-focus no-close-on-backdrop centered hide-footer>
              <template #modal-title>
                <h2>Schenking</h2>
              </template>
              <NewGift :loanType="loanType" :edit="editing" @contractChanged="contractGiftChanged" :editing="current_gift_contract" />
            </b-modal>
            <b-col class="d-flex" cols="12" sm="6" md="4" lg="3" @click="loanType = 'GiftContract'; $bvModal.show('new-gift')">
              <b-card class="contract">
                <div class="card-title">Schenking</div>
                <div class="d-flex">
                  <i class="mb-1">
                    <img src="@/assets/images/illustration/schenking.png" alt="" style="width: 50px; height: 50px;">
                  </i>
                </div>
                <b-card-text>
                  Een schenking is een geldbedrag dat je zomaar krijgt of weggeeft. De tegenpartij hoeft hier niets voor te doen.
                </b-card-text>
              </b-card>
            </b-col>

            <b-modal :id="'new-loan'" size="lg" no-enforce-focus no-close-on-backdrop centered hide-footer>
              <template #modal-title>
                <h2>{{ $t(`contracts.${loanType}`) }}</h2>
              </template>
              <NewLoan :loanType="loanType" :edit="editing" @contractChanged="contractLoanChanged" :editing="current_loan_contract" />
            </b-modal>
            <b-col class="d-flex" cols="12" sm="6" md="4" lg="3" @click="loanType = 'LinearLoanContract'; $bvModal.show('new-loan')">
              <b-card class="contract">
                <div class="card-title">Lineaire lening</div>
                <div class="d-flex">
                  <i class="mb-1">
                    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.5" y1="-2.18557e-08" x2="0.500002" y2="48" stroke="black" />
                      <line x1="48" y1="48.5" y2="48.5" stroke="black" />
                      <line x1="3.27038" y1="9.57941" x2="45.2704" y2="36.5794" stroke="black" />
                    </svg>
                  </i>
                </div>
                <b-card-text>
                  Bij een lineaire lening staat je aflossing vast. Daarentegen neemt je rente iedere betalingstermijn juist af.
                </b-card-text>
              </b-card>
            </b-col>

            <b-col class="d-flex" cols="12" sm="6" md="4" lg="3" @click="loanType = 'AnnuityLoanContract'; $bvModal.show('new-loan')">
              <b-card class="contract">
                <div class="card-title">Annuïteit</div>
                <div class="d-flex">
                  <i class="mb-1">
                    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 0L0.500002 48" stroke="black" />
                      <path d="M48 48.5H0" stroke="black" />
                      <path d="M3 14.5251L7.83192 15.605C23.1451 19.0272 36.4965 28.3382 45 41.5251" stroke="black" />
                      <path d="M3.5 6.5H45" stroke="black" />
                    </svg>
                  </i>
                </div>
                <b-card-text>
                  Bij een annuïtenlening zijn je maandlasten iedere maand hetzelfde. Naarmate je meer aflost, wordt het rente-deel van je maandlasten lager.
                </b-card-text>
              </b-card>
            </b-col>

            <b-col class="d-flex" cols="12" sm="6" md="4" lg="3" @click="loanType = 'RepaymentFreeLoanContract'; $bvModal.show('new-loan')">
              <b-card class="contract">
                <div class="card-title">Aflossingsvrije lening</div>
                <div class="d-flex">
                  <i class="mb-1">
                    <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 0.377441L0.500002 48.3774" stroke="black" />
                      <path d="M48 48.8774H0" stroke="black" />
                      <path d="M4 42H40.2045" stroke="black" />
                      <path d="M40 6V42.2045" stroke="black" />
                    </svg>

                  </i>
                </div>
                <b-card-text>
                  Bij een aflossingsvrije lening wordt maandelijks rente betaald, maar wordt er niet afgelost. Aan het einde van de periode dient het gehele bedrag afgelost te worden. <br /> <b>Let op:</b> deze leningsvorm is niet aftrekbaar van de belastingen.
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </div>
    <div v-if="contracts.length > 0">
      <section>
        <b-container>
          <b-row class="d-flex justify-content-center">
            <b-col cols="12" sm="6" lg="3">
              <statistic-card-horizontal icon="CreditCardIcon" :statistic="f.format(loans_total)" statistic-title="Totaal aan leningen" color="success" />
            </b-col>

            <b-col cols="12" sm="6" lg="3">
              <statistic-card-horizontal icon="GiftIcon" :statistic="f.format(gifts_total)" statistic-title="Totaal aan schenkingen" color="info" />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <div>
        <section>
          <b-container>
            <b-row>
              <b-col>
                <h1>Openstaande leningen</h1>
                <div v-if="$auth.user && $auth.hasRole(['admin', 'superadmin', 'sender'])">
                  <!-- <b-modal id="data-lost" no-enforce-focus modal-class="modal-danger" 
                    ok-variant="danger"
                    ok-title="Afsluiten"
                    cancel-title="Blijven"
                    title="Let op! Invoer gaat verloren"
                    @ok="closeWindowAccept"
                  >
                    <b-card-text>
                      Je staat op het punt het scherm af te sluiten. Alle invoer gaat verloren.
                    </b-card-text>
                  </b-modal> -->

                  <b-modal id="new-loan" size="lg" no-enforce-focus no-close-on-backdrop centered hide-footer>
                    <template #modal-title>
                      <h2>{{ $t(`contracts.${loanType}`) }}</h2>
                    </template>
                    <NewLoan :loanType="loanType" :edit="editing" @contractChanged="contractLoanChanged" :editing="current_loan_contract" />
                  </b-modal>

                  <b-modal id="new-gift" size="lg" no-enforce-focus no-close-on-backdrop centered hide-footer>
                    <template #modal-title>
                      <h2>Schenking</h2>
                    </template>
                    <NewGift :loanType="loanType" :edit="editing" @contractChanged="contractGiftChanged" :editing="current_gift_contract" />
                  </b-modal>

                </div>

                <div v-if="contracts.filter(e => e.type.indexOf('LoanContract') > -1).length == 0" class="d-flex flex-column align-items-center">
                  <div>Je hebt nog geen ondertekende leningen.</div>
                </div>
                <div v-if="contracts.length">
                  <div class="justify-content-between align-items-center mb-1">
                    <div v-if="$auth.user && $auth.hasRole(['admin', 'superadmin', 'sender'])">
                      <b-button variant="primary" class="" v-b-modal.mutation>
                        Betaling registreren
                        <InfoButton id="new-repayment-info" text="Je kunt een betaling registreren aan een openstaande lening." />
                      </b-button>
                      <b-modal id="mutation" size="md" no-enforce-focus no-close-on-backdrop hide-footer>
                        <template #modal-title>
                          <h2>Betaling registreren</h2>
                        </template>
                        <MutationModal v-if="contracts" :contracts="contracts" />
                      </b-modal>
                    </div>
                  </div>
                  <div>
                    <b-row>
                      <b-col cols="12" sm="6" lg="4" class="d-flex" v-if="$auth.user && $auth.hasRole(['admin', 'superadmin', 'sender'])">
                        <b-card class="new contract w-100">
                          <div class="d-flex flex-column align-items-center justify-content-around text-center" style="height:100%;">
                            <h1 class="mb-2">
                              Voeg nieuwe lening toe
                            </h1>
                            <feather-icon class="mb-2" icon="FileTextIcon" size="50"></feather-icon>
                            <b-button class="mb-2" pill variant="primary" v-b-modal.choose-loan-modal>Nieuwe lening</b-button>

                            <b-modal id="choose-loan-modal" size="lg" no-enforce-focus no-close-on-backdrop hide-footer centered>
                              <template #modal-title>
                                <h2>Kies een nieuwe lening</h2>
                              </template>
                              <ChooseLoanModal @newContractSelected="newContractSelected" />
                            </b-modal>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col class="d-flex" cols="12" sm="6" lg="4" v-for="(contract, index_c) in  contracts.filter(e => e.type.indexOf('LoanContract') > -1) " :key="'C' + index_c">
                        <b-card class="contract w-100">
                          <div class="d-flex flex-column align-items-center justify-content-between">
                            <h1 class="mb-2">
                              {{ $t(`contracts.${contract.type}`) }}
                            </h1>
                            <b-avatar-group class="mb-2">
                              <template v-if="contract.senders">
                                <b-avatar v-for="(stakeholder, index_s) in contract.senders" :key="'S' + index_s" v-b-tooltip.hover :title="stakeholder.fullname" class="pull-up" :src="stakeholder.image" :text="!stakeholder.image ? stakeholder.initials : null"></b-avatar>
                              </template>
                              <template v-if="contract.receivers">
                                <b-avatar v-for="(stakeholder, index_r) in contract.receivers" :key="'R' + index_r" v-b-tooltip.hover :title="stakeholder.fullname" class="pull-up" :src="stakeholder.image" :text="!stakeholder.image ? stakeholder.initials : null"></b-avatar>
                              </template>
                            </b-avatar-group>
                            <h6 class="mb-2">
                              <!--<span v-b-tooltip.hover title="Dit bedrag staat nog open">{{f.format(contract.total)}}</span> / --><span v-b-tooltip.hover title="Dit is het oorspronkelijk uitgeleende bedrag">{{ f.format(contract.total) }}</span>
                            </h6>
                            <div class="d-block text-center">
                              <b-button class="mb-1 m-1" pill variant="primary" @click="viewContract(contract)">Bekijk</b-button>
                              <b-button v-if="$auth.user && $auth.hasRole(['admin', 'sender'])" class="mb-1 m-1" pill variant="primary" @click="downloadContract(contract.hashid)">Download</b-button>

                            </div>
                            <div class="mb-2 d-flex justify-content-between" style="width:100%;">
                              <div class="d-flex flex-column">
                                <b>Start</b>
                                <span>{{ dutchDateFormat(contract.start_date) }}</span>
                              </div>
                              <div class="d-flex flex-column text-right">
                                <b>Maanden te gaan</b>
                                <span v-if="contract.contract.periods_left >= 0">{{ contract.contract.periods_left }} maanden</span>
                                <span v-else>Contract afgelopen</span>
                              </div>
                            </div>
                            <strong v-if="contract.contract.signed_date === null" class="contract-status">
                              NIET ONDERTEKEND
                            </strong>
                            <strong v-if="contract.contract.signed_date" class="contract-status">
                              ONDERTEKEND
                            </strong>
                            <b-row v-if="$auth.user && $auth.hasRole(['admin', 'sender'])">
                              <b-avatar v-if="contract.contract.signed_date === null" button variant="primary" class="align-baseline mt-1" @click="editContract(contract)" v-b-tooltip.hover title="Wijzig dit contract">
                                <feather-icon icon="EditIcon" class="" />
                              </b-avatar>
                              <b-avatar v-if="contract.contract.signed_date === null || $auth.hasRole(['superadmin'])" button variant="danger" class="align-baseline mt-1 ml-1" v-b-modal="`delete-contract-u-` + index_c" v-b-tooltip.hover title="Verwijder dit contract">
                                <feather-icon icon="TrashIcon" class="" />
                              </b-avatar>
                              <b-modal :id="`delete-contract-u-` + index_c" no-enforce-focus no-close-on-backdrop modal-class="modal-danger" ok-variant="danger" ok-title="Verwijderen" cancel-title="Annuleer" title="Verwijder contract" @ok="deleteContract(contract.hashid)">
                                <b-card-text>
                                  Je staat op het punt je contract te verwijderen. Dit kan niet ongedaan worden gemaakt.
                                </b-card-text>
                              </b-modal>
                            </b-row>
                          </div>
                        </b-card>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>

        <section>
          <b-container>
            <b-row>
              <b-col>
                <h1>Schenkingen</h1>
                <div v-if="$auth.user && $auth.hasRole(['admin', 'superadmin', 'sender'])">
                  <div v-if="contracts.filter(e => e.type.indexOf('GiftContract') > -1).length == 0" class="d-flex flex-column align-items-center">
                    <div>Je hebt nog geen schenkingen aangemaakt.</div>
                    <b-button variant="primary" class="mt-2" @click="$bvModal.show('new-gift'); editing = null">
                      Nieuwe schenking
                    </b-button>
                  </div>
                </div>
                <div v-else>
                  <div v-if="contracts.filter(e => e.type.indexOf('GiftContract') > -1).length == 0" class="d-flex flex-column align-items-center">
                    <div>Je hebt nog geen schenkingen ontvangen.</div>
                  </div>
                </div>
                <div v-if="contracts.filter(e => e.type.indexOf('GiftContract') > -1).length" class="">
                  <div v-if="$auth.user && $auth.hasRole(['admin', 'superadmin', 'sender'])">
                    <div class="d-flex justify-content-between">
                      <b-button variant="primary" class="mr-3" @click="$bvModal.show('new-gift'); editing = null">
                        Nieuwe schenking
                        <InfoButton id="gift-schenking" text="Een schenking is een geldbedrag dat gegeven wordt aan de ontvanger. De ontvanger hoeft dit bedrag niet terug te betalen." />
                      </b-button>
                    </div>
                  </div>
                  <b-row class="mt-1">
                    <b-col cols="12" sm="6" md="4" v-for="( contract, index_c ) in  contracts.filter(e => e.type.indexOf('GiftContract') > -1) " :key="'C' + index_c">
                      <b-card class="contract">
                        <div class="d-flex flex-column align-items-center justify-content-between">
                          <h1 class="mb-2">
                            Schenking
                          </h1>
                          <b-avatar-group class="mb-2">
                            <template v-if="contract.senders">
                              <b-avatar v-for="( stakeholder, index_r ) in  contract.senders " :key="'R' + index_r" v-b-tooltip.hover :title="stakeholder.fullname" class="pull-up" :src="stakeholder.image" :text="!stakeholder.image ? stakeholder.initials : null"></b-avatar>
                            </template>
                            <template v-if="contract.receivers">
                              <b-avatar v-for="( stakeholder, index_s ) in  contract.receivers " :key="'S' + index_s" v-b-tooltip.hover :title="stakeholder.fullname" class="pull-up" :src="stakeholder.image" :text="!stakeholder.image ? stakeholder.initials : null"></b-avatar>
                            </template>
                          </b-avatar-group>
                          <h6 class="mb-2">
                            <span>{{ f.format(contract.total) }}</span>
                          </h6>
                          <b-button v-if="$auth.user && $auth.hasRole(['admin', 'sender'])" class="mb-2" pill variant="primary" @click="downloadGiftContract(contract.hashid)">Download</b-button>

                          <div class="mb-2 d-flex justify-content-between" style="width:100%;">
                            <div class="d-flex flex-column">
                              <b>Geschonken op</b>
                              <span>{{ dutchDateFormat(new Date(contract.start_date)) }}</span>
                            </div>
                            <div class="d-flex flex-column text-right">
                              <b>Fiscaal jaar</b>
                              <span>{{ new Date(contract.start_date).getFullYear() }}</span>
                            </div>
                          </div>
                          <b-row v-if="$auth.user && $auth.hasRole(['admin', 'sender'])">
                            <b-avatar button variant="primary" class="align-baseline mt-1" @click="editGiftContract(contract)" v-b-tooltip.hover title="Wijzig dit contract">
                              <feather-icon icon="EditIcon" class="" />
                            </b-avatar>
                            <b-avatar button variant="danger" class="align-baseline mt-1 ml-1" v-b-modal="`delete-contract-c-` + index_c" v-b-tooltip.hover title="Verwijder dit contract">
                              <feather-icon icon="TrashIcon" class="" />
                            </b-avatar>
                            <b-modal :id="`delete-contract-c-` + index_c" no-enforce-focus no-close-on-backdrop modal-class="modal-danger" ok-variant="danger" ok-title="Verwijderen" cancel-title="Annuleer" title="Verwijder contract" @ok="deleteContract(contract.hashid)">
                              <b-card-text>
                                Je staat op het punt je contract te verwijderen. Dit kan niet ongedaan worden gemaakt.
                              </b-card-text>
                            </b-modal>
                          </b-row>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// import store from '@/store'
// import NoContracts from './NoContracts.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import InfoButton from '@/components/InfoButton.vue'
import MutationModal from '@/components/modals/MutationModal.vue'
import ChooseLoanModal from '@/views/overview/modals/ChooseLoanModal.vue'
import NewLoan from '@/views/overview/modals/NewLoan.vue'
import NewGift from '@/views/overview/modals/NewGift.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FamilyImageBanner from '@/components/family-image-banner.vue'


import moment from 'moment'

import { mapActions, mapGetters } from "vuex"

export default {
  components: {
    // NoContracts,
    StatisticCardHorizontal,
    InfoButton,
    MutationModal,
    ChooseLoanModal,
    NewLoan,
    NewGift,
    FamilyImageBanner,
  },
  data() {
    return {
      f: new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, }),
      editing: null,
      loanType: null,
      current_gift_contract: null,
      current_loan_contract: null,
    }
  },
  created() {

  },
  mounted() {
    this.getContracts();
  },
  computed: {
    ...mapGetters({
      contracts: "contracts/get",
      gifts_total: "contracts/get_total_gifts",
      loans_total: "contracts/get_total_loans"
    })
  },
  methods: {
    ...mapActions({
      getContracts: "contracts/all",
      removeContract: "contracts/remove",
      previewContract: "contracts/preview",
      downloadContract: "contracts/download"
    }),
    deleteContract(hashid) {
      let _this = this;
      this.removeContract(hashid).then(() => {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Contract verwijderd',
            icon: 'BellIcon',
            text: 'Je contract is verwijderd.',
            variant: 'danger',
          },
        })
      });
    },
    contractLoanChanged(contract) {
      this.current_loan_contract = contract
    },
    contractGiftChanged(contract) {
      this.current_gift_contract = contract
    },
    closeWindowAccept() {
      console.log("asdasd")

      this.$bvModal.hide('data-lost');
      this.$bvModal.hide('new-gift');
      this.$bvModal.hide('new-loan');
    },
    closeWindow(event) {
      event.preventDefault();
      this.$bvModal.show('data-lost');
    },
    downloadGiftContract(hashid) {
      this.downloadContract(hashid);
    },
    viewContract(contract) {
      this.$router.push({ name: 'loan', params: { id: contract.hashid } })
    },
    dutchDateFormat: function (time) {
      return moment(time).locale('nl').format('LL');
    },
    savedGift(gift) {
      this.$bvModal.hide('new-gift')
      this.contracts.push(gift)
      console.log(this.contracts)
    },
    newContractSelected(contractType) {
      this.editing = null
      this.loanType = contractType
      this.$bvModal.hide('choose-loan-modal')
      if (contractType === 'GiftContract') {
        this.$bvModal.show('new-gift')
        return;
      }
      this.$bvModal.show('new-loan')
    },
    editGiftContract(contract) {
      this.editing = contract.hashid
      this.loanType = contract.type
      this.$bvModal.show('new-gift')
    },
    editContract(contract) {
      this.editing = contract.hashid
      this.loanType = contract.type
      this.$bvModal.show('new-loan')
    }
  },
}
</script>

<style lang="scss" scoped>
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: transparent !important;
}

.contract {
  //This makes sure the divs are the same height during hover and normal state. This gets rid of the 'jumping' behavior observed otherwise
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--primary);
    background-color: #F5FDFF;
    cursor: pointer;
  }
}

.contract:hover {
  border: 1px solid var(--primary);
  // background-color: #F5FDFF;
}

.contract-status {
  color: var(--primary);
}
</style>
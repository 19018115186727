<template>
  <div>
    <div v-if="$auth.db.children.length == 0">
      <NoChildModal />
    </div>
    <div v-else>
      <p class="text-center">Je hebt gekozen voor een {{ modalContent.description[loanType] }}. Door het onderstaande formulier in te vullen, stel je jouw {{ modalContent.description[loanType] }} op. Je kunt de lening bekijken door op de 'preview' knop te drukken. Wanneer je alles hebt ingevuld, druk dan op 'aanmaken', wij genereren dan het contract en je kunt daarna het contract downloaden.</p>

      <b-container class="mt-3">
        <validation-observer ref="newLoan" v-slot="{ handleSubmit, errors }">
          <b-container class="mt-3">
            <h3>Wie is de uitlener?</h3>
            <b-row>
              <b-col cols="8">
                <validation-provider #default="{ errors }" name="Lener" rules="required">
                  <v-select label="firstname" v-model="newLoan.senders[0]" :options="familyMembers" transition="" @input="updateSenderList">

                    <template slot="selected-option" slot-scope="familyMember">
                      <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                      <span class="ml-1">{{ familyMember.fullname }}</span>
                    </template>

                    <template v-slot:option="familyMember">
                      <div class="d-flex align-items-center">
                        <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                        <span class="ml-1">{{ familyMember.fullname }}</span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <div class="pl-1 pt-1">
                  <b-avatar-group>
                    <!-- <b-avatar v-b-tooltip.hover v-if="newLoan.senders.length > 0 && newLoan.senders[0]" :title="`${newLoan.senders[0].firstname} ${newLoan.senders[0].lastname}`" class="pull-up" :src="newLoan.senders[0].image" :text="!newLoan.senders[0].image ? newLoan.senders[0].initials : null"></b-avatar> -->
                    <b-avatar v-b-tooltip.hover v-if="newLoan.senders.length > 1" :title="`${newLoan.senders[1].firstname} ${newLoan.senders[1].lastname}`" class="pull-up" :src="newLoan.senders[1].image" :text="!newLoan.senders[1].image ? newLoan.senders[1].initials : null"></b-avatar> <span v-if="newLoan.senders.length > 1" class="pl-1 align-content-center">{{ `${newLoan.senders[1].firstname}
                      ${newLoan.senders[1].lastname}` }}</span>
                  </b-avatar-group>
                </div>
              </b-col>
              <b-col cols="4">
                <b-button v-if="newLoan.senders.length == 1 && newLoan.senders[0].partners && newLoan.senders[0].partners.length > 0" class="ml-2" variant="primary" @click="addPartner()">De partner tekent ook</b-button>
                <b-button v-if="newLoan.senders.length == 2" class="ml-2" variant="primary" @click="deletePartner()">Partner verwijderen</b-button>
              </b-col>
            </b-row>

            <h3 class="mt-1">Wie is de lener?</h3>
            <b-row>
              <b-col cols="8">
                <!-- in the vue select, we use the 'transition' prop. Without this set, the modal will close if you select an item that is outside of the modal (for reference: https://github.com/sagalbot/vue-select/issues/1244 ) -->
                <label>Naam lener</label>
                <validation-provider #default="{ errors }" name="Lener" rules="required">
                  <v-select label="firstname" v-model="familyMemberSelected" :options="familyMembers" transition="" @input="setChildInnewContract">

                    <template slot="selected-option" slot-scope="familyMember">
                      <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                      <span class="ml-1">{{ familyMember.fullname }}</span>
                    </template>

                    <template v-slot:option="familyMember">
                      <div class="d-flex align-items-center">
                        <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                        <span class="ml-1">{{ familyMember.fullname }}</span>
                      </div>
                    </template>

                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-col>
              <b-col cols="4" v-if="!familyMemberSelected" class="d-flex align-items-end">
                <b-button @click="$router.push({ path: 'familiebeheer/relaties' })">Voeg extra familielid toe</b-button>
              </b-col>
              <b-col cols="4" v-if="familyMemberSelected && familyMemberSelected.partners.length > 0">
                <label>Tekent {{ familyMemberSelected.partners[0].partner.firstname }} ook mee?</label>
                <b-form-checkbox v-model="newLoan.receiverPartnerSelected">
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-container>

          <app-collapse accordion type="Margin">
            <app-collapse-item @id="getCollapseID" title="Doel van de lening" class="mt-3">
              <b-row class="mt-1">
                <b-col>
                  <label class="d-flex">
                    Wat is het doel van de lening?
                  </label>
                  <validation-provider #default="{ errors }" name="Doel van de lening" rules="required">
                    <b-form-select :options="contractGoal" v-model="newLoan.data.goal.type"></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>
              </b-row>
              <b-row class="mt-1" v-if="newLoan.data.goal.type == 'house'">
                <b-col class="mb-1" cols="12" sm="" lg="">
                  <label class="d-flex">
                    Is het adres van de woning al bekend?
                  </label>
                  <b-button-group>
                    <b-button :variant="newLoan.data.addressKnown ? 'primary' : 'variant'" @click="newLoan.data.addressKnown = true" :pressed="newLoan.data.addressKnown ? true : false">Ja</b-button>
                    <b-button :variant="!newLoan.data.addressKnown ? 'primary' : 'variant'" @click="newLoan.data.addressKnown = false" :pressed="!newLoan.data.addressKnown ? true : false">Nee</b-button>
                  </b-button-group>
                </b-col>
              </b-row>
              <b-row class="mt-1" v-if="newLoan.data.goal.type == 'house' && newLoan.data.addressKnown">
                <b-col class="mb-1" cols="12" sm="6" lg="">
                  <validation-provider #default="{ errors }" name="Straatnaam" rules="required">
                    <label class="d-flex">
                      Straatnaam
                    </label>
                    <b-form-input v-model="newLoan.data.goal.value.house.street" placeholder="Fank straat" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" cols="12" sm="6" lg="">
                  <validation-provider #default="{ errors }" name="Huisnummer" rules="required|max:6">
                    <label class="d-flex">
                      Huisnummer
                    </label>
                    <b-form-input v-model="newLoan.data.goal.value.house.housenumber" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" cols="12" sm="6" lg="">
                  <validation-provider #default="{ errors }" name="postcode" rules="required|alpha_num">
                    <label class="d-flex">
                      Postcode
                    </label>
                    <b-form-input v-model="newLoan.data.goal.value.house.postcode" v-mask="'####AA'" placeholder="1234AB" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" cols="12" sm="6" lg="">
                  <validation-provider #default="{ errors }" name="Stad" rules="required">
                    <label class="d-flex">
                      Stad
                    </label>
                    <b-form-input v-model="newLoan.data.goal.value.house.city" placeholder="Groningen" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" cols="12" sm="6" lg="">
                  <validation-provider #default="{ errors }" name="land" rules="required">
                    <label class="d-flex">
                      Land
                    </label>
                    <b-form-input v-model="newLoan.data.goal.value.house.country" disabled />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mt-1" v-if="newLoan.data.goal.type == 'mortgage'">
                <b-col cols="12" sm="6" md="">
                  <validation-provider #default="{ errors }" name="Bank naam" rules="required">
                    <label class="d-flex">
                      Naam van de bank waar de hypotheek momenteel loopt
                    </label>
                    <b-form-input placeholder="Triodos Bank N.V." v-model="newLoan.data.goal.value.bankName" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mt-1" v-if="newLoan.data.goal.type == 'object'">
                <b-col cols="12" sm="" md="">
                  <validation-provider #default="{ errors }" name="omschrijving object" rules="required">
                    <label class="d-flex">
                      Maak de volgende zin af "de lening is bedoeld voor: de aanschaf van ..."
                    </label>
                    <b-form-input v-model="newLoan.data.goal.value.objectDescription" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mt-1" v-if="newLoan.data.goal.type == 'other'">
                <b-col cols="12" sm="6" md="">
                  <validation-provider #default="{ errors }" name="Omschrijving lening" rules="required">
                    <label class="d-flex">
                      Maak de volgende zin af "de lening is bedoeld voor: de aanschaf van ..."
                    </label>
                    <b-form-input v-model="newLoan.data.goal.value.otherDescription" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

            </app-collapse-item>
            <app-collapse-item @id="getCollapseID" title="Algemeen" class="mt-1">
              <b-row>
                <b-col class="mb-1" cols="12" sm="6">
                  <label class="d-flex">
                    Wanneer gaat de lening in?
                  </label>
                  <validation-provider #default="{ errors }" name="Begin datum" rules="required">
                    <b-form-datepicker locale="nl" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="newLoan.start_date" v-bind="datepickerLabel"></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" cols="12" sm="6">
                  <label class="d-flex">
                    Hoeveel wil je uitlenen?
                  </label>
                  <validation-provider #default="{ errors }" name="Contract bedrag" rules="required|min_value:0|max_value:10000000">
                    <b-input-group prepend="€">
                      <cleave class="form-control" v-model.number="newLoan.total" :options="maskOptions.currency" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mb-1" cols="12" lg="6">
                  <validation-provider #default="{ errors }" name="Besteding binnen aantal maanden" rules="required|numeric|max_value:99">
                    <label class="d-flex">
                      Binnen welke tijdsperiode moet de lening besteed zijn aan het hiervoor genoemde doel?
                    </label>
                    <b-input-group>
                      <b-form-input v-model.number="spendLoanBefore.value" :state="errors.length > 0 ? false : null" />
                      <b-input-group-append>
                        <b-dropdown :text="spendLoanBefore.type" variant="outline-primary" right>
                          <b-dropdown-item @click="spendLoanBefore.type = 'Maanden'">Maanden</b-dropdown-item>
                          <b-dropdown-item @click="spendLoanBefore.type = 'Kwartalen'">Kwartalen</b-dropdown-item>
                          <b-dropdown-item @click="spendLoanBefore.type = 'Halfjaren'">Halfjaren</b-dropdown-item>
                          <b-dropdown-item @click="spendLoanBefore.type = 'Jaren'">Jaren</b-dropdown-item>
                        </b-dropdown>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" cols="12" v-if="newLoan.data.goal.type == 'house'">
                  <label class="d-flex">
                    Streef je er naar de lening te kwalificeren als een lening voor de eigen woning met hypotheekrenteaftrek?
                  </label>
                  <b-button-group>
                    <b-button :variant="newLoan.data.mortgageInterestDeduction == true ? 'primary' : 'variant'" @click="newLoan.data.mortgageInterestDeduction = true" :pressed="newLoan.data.mortgageInterestDeduction ? true : false">Ja</b-button>
                    <b-button :variant="newLoan.data.mortgageInterestDeduction == false ? 'primary' : 'variant'" @click="newLoan.data.mortgageInterestDeduction = false" :pressed="!newLoan.data.mortgageInterestDeduction ? true : false">Nee</b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item @id="getCollapseID" title="Contractduur" class="mt-1">
              <b-row class="mt-1">
                <b-col cols="12" sm="8" md="6" class="d-flex align-self-end">
                  <validation-provider #default="{ errors }" name="Duur lening" :rules="'required|between:1,' + maxContractLengthValidationRule">
                    <label class="d-flex">
                      Looptijd van het contract
                    </label>
                    <b-input-group>
                      <b-form-input v-model.number="contractDuration.value" :state="errors.length > 0 ? false : null" />
                      <b-input-group-append>
                        <b-dropdown :text="contractDuration.type" variant="outline-primary" right>
                          <b-dropdown-item @click="contractDuration.type = 'Maanden'">Maanden</b-dropdown-item>
                          <b-dropdown-item @click="contractDuration.type = 'Kwartalen'">Kwartalen</b-dropdown-item>
                          <b-dropdown-item @click="contractDuration.type = 'Halfjaren'">Halfjaren</b-dropdown-item>
                          <b-dropdown-item @click="contractDuration.type = 'Jaren'">Jaren</b-dropdown-item>
                        </b-dropdown>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="newLoan.length > 360 && (newLoan.data.goal.type == 'house' || newLoan.data.goal.type == 'mortgage')"><strong>Let op:</strong> wanneer je de lening in meer dan 360 maanden (30 jaar) aflost, heb je geen recht op hypotheekrente aftrek.</small>
                  </validation-provider>
                </b-col>
                <b-col cols="12" sm="6" v-if="newLoan.start_date && newLoan.length">
                  <validation-provider #default="{ errors }" name="Einddatum contract">
                    <label class="d-flex">
                      Daarmee eindigt de lening op de volgende datum
                    </label>
                    <b-form-datepicker locale="nl" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="newLoan.end_date" v-bind="datepickerLabel" :disabled="true"></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item @id="getCollapseID" title="" class="mt-1">
              <template v-slot:header>
                <div class="d-flex items-center">
                  <span class="lead collapse-title">Aflossing en rente</span>
                  <InfoButton id="aflossing-en-rente" text="De termijn voor aflossen mag je zelf bepalen. Voor de fiscale aftrekbaarheid kan het voordelig zijn om een langere aflossingstermijn af te spreken. Meer informatie vind je hier: <a href='https://familiebank.nl/termijn-voor-lening-aflossen/' target='_blank'>https://familiebank.nl/termijn-voor-lening-aflossen/</a>" :bgBlue="true" />
                </div>
              </template>
              <b-row class="mt-1">
                <b-col sm="6" md="">
                  <b-form-group>
                    <div class="d-flex">
                      <label>Welk rente percentage is van toepassing?</label>
                      <InfoButton id="rente-percentage"
                        text="Je moet ook binnen de familie rente berekenen over een lening. Een redelijke rente sluit aan bij de gebruikelijke rente in de markt. Plus moet die rente rekening houden met eventuele risico's en zekerheden. Meer informatie vind je hier: <a href='https://familiebank.nl/redelijke-rente-familielening/' target='_blank'>https://familiebank.nl/redelijke-rente-familielening/</a>"
                        :bgBlue="true" />
                    </div>
                    <validation-provider #default="{ errors }" name="Rente percentage" rules="required|min_value:0.01|max_value:99">
                      <b-input-group append="%">
                        <b-form-input type="number" v-model.number="newLoan.interest" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col class="d-flex align-self-end" sm="6" md="">
                  <b-form-group>
                    <div class="d-flex">
                      <label>Rentevastperiode</label>
                      <InfoButton id="rentevastperiode-id" text="De duur van deze periode is afhankelijk van de specifieke omstandigheden en afspraken tussen de familieleden, en kan variëren van één tot vijftien jaar, afhankelijk van factoren zoals financiële situaties en risicobereidheid." :bgBlue="true" />
                    </div>
                    <validation-provider #default="{ errors }" name="Rentevastperiode" rules="required|min_value:0">
                      <b-input-group>
                        <b-form-input type="number" v-model.number="fixedInterestPeriod.value" :state="errors.length > 0 ? false : null" />
                        <b-input-group-append>
                          <b-dropdown :text="fixedInterestPeriod.type" variant="outline-primary" right>
                            <b-dropdown-item @click="fixedInterestPeriod.type = 'Maanden'">Maanden</b-dropdown-item>
                            <b-dropdown-item @click="fixedInterestPeriod.type = 'Kwartalen'">Kwartalen</b-dropdown-item>
                            <b-dropdown-item @click="fixedInterestPeriod.type = 'Halfjaren'">Halfjaren</b-dropdown-item>
                            <b-dropdown-item @click="fixedInterestPeriod.type = 'Jaren'">Jaren</b-dropdown-item>
                          </b-dropdown>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col>
                  <div>
                    <label v-if="loanType == 'AnnuityLoanContract'">Termijnbedrag</label>
                    <label v-else-if="loanType == 'RepaymentFreeLoanContract'">Rentebedrag</label>
                    <label v-else>Aflosbedrag</label>
                    <InfoButton id="betaalbedrag-id"
                      text="Hier zie je het periodieke bedrag dat je betaalt voor je lening. Dit kan rente, aflossing of beide omvatten, afhankelijk van je type lening. Bij een lineaire lening zie je hier alleen de rente; de aflossing varieert namelijk per termijn. Na het aanmaken van de overeenkomst wordt het volledige betaalschema met zowel rente als aflossing zichtbaar." :bgBlue="true" />
                  </div>
                  <b-input-group prepend="€">
                    <b-form-input type="number" :disabled="true" :value="fixed_payment" />
                  </b-input-group>
                </b-col>
                <b-col cols="12" md="6">
                  <label>Termijnbetaling</label>
                  <b-form-select :options="[{ value: 12, text: 'jaarlijks' }, { value: 6, text: 'halfjaarlijks' }, { value: 3, text: 'kwartaalijks' }, { value: 1, text: 'maandelijks' }]" v-model="newLoan.payment_period"></b-form-select>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item @id="getCollapseID" title="Overig" class="mt-1">
              <b-row class="mt-1">

                <b-col class="mb-1" cols="12" md="6">
                  <label class="d-flex">
                    Afsluitprovisie
                    <InfoButton id="afsluitprovisie-id" text="Het is niet verplicht om een afsluitprovisie te rekenen. Het kan wel fiscaal voordelig zijn. Meer informatie vind je hier: <a href='https://familiebank.nl/afsluitprovisie-rekenen/' target='_blank'>https://familiebank.nl/afsluitprovisie-rekenen</a>" :bgBlue="true" />
                  </label>
                  <validation-provider #default="{ errors }" name="Afsluitprovisie" rules="required|between:0,1.5">
                    <b-input-group append="%">
                      <b-form-input type="number" min="0" max="1.5" v-model.number="newLoan.data.closingCommission" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" v-if="newLoan.data.goal.type == 'house'">
                  <label class="d-flex">
                    Zijn er andere leningen op de woning van andere geldschieters?
                  </label>
                  <b-button-group>
                    <b-button :variant="newLoan.data.otherLoansOnHouse == true ? 'primary' : 'variant'" @click="newLoan.data.otherLoansOnHouse = true" :pressed="newLoan.data.otherLoansOnHouse ? true : false">Ja</b-button>
                    <b-button :variant="newLoan.data.otherLoansOnHouse == false ? 'primary' : 'variant'" @click="newLoan.data.otherLoansOnHouse = false" :pressed="!newLoan.data.otherLoansOnHouse ? true : false">Nee</b-button>
                  </b-button-group>
                </b-col>
              </b-row>
              <b-row class="mb-1" cols="12" md="6">
                <b-col class="mb-1" v-if="newLoan.data.goal.type == 'house'">
                  <label class="d-flex">
                    Wil je hypotheekrecht van toepassing laten zijn?
                  </label>
                  <b-button-group>
                    <b-button :variant="newLoan.data.mortgageRights == true ? 'primary' : 'variant'" @click="newLoan.data.mortgageRights = true" :pressed="newLoan.data.mortgageRights ? true : false">Ja</b-button>
                    <b-button :variant="newLoan.data.mortgageRights == false ? 'primary' : 'variant'" @click="newLoan.data.mortgageRights = false" :pressed="!newLoan.data.mortgageRights ? true : false">Nee</b-button>
                  </b-button-group>
                  <div class="mortgage-rights-notification" v-if="newLoan.data.mortgageRights"><strong>Let op:</strong> Als je een hypotheekrecht wil, moet dit via de notaris geregeld worden. Met een hypotheekrecht kun je de woning verkopen als de lener niet betaalt. In de praktijk kiezen familieleden meestal voor een lening zonder hypotheekrecht. Je kunt gewoon verdergaan met het aanmaken van het
                    contract. Als je een hypotheekrecht wilt toevoegen, nemen wij contact met je op voor de verdere stappen.
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col class="mb-1">
                  <label class="d-flex">
                    Plaats van ondertekening
                  </label>
                  <validation-provider #default="{ errors }" name="Plaats van ondertekening" rules="required">
                    <b-input-group>
                      <b-form-input type="text" placeholder="Plaats" v-model="newLoan.data.placeOfSignature" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col class="mb-1" cols="12" md="6">
                  <label class="d-flex">
                    Datum van ondertekening
                  </label>
                  <validation-provider #default="{ errors }" name="Datum van ondertekening" rules="required">
                    <b-form-datepicker locale="nl" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="newLoan.data.dateOfSignature" v-bind="datepickerLabel"></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </app-collapse-item>

          </app-collapse>

          <small class="d-flex justify-content-center mt-2 text-danger" v-if="displayErrors(errors).length > 0">
            <div>
              De volgende fouten zijn opgetreden:
              <ul>
                <li v-for="(error, index) in displayErrors(errors)" :key="index">{{ error }}</li>
              </ul>
            </div>
          </small>

          <b-row class="text-center">
            <b-col class="text-center">
              <b-button variant="primary m-1" @click="handleSubmit(previewContract)">Bekijken</b-button>
              <b-button variant="success m-1" @click="handleSubmit(sendContract); displayErrors($refs.newLoan.errors)">{{ edit ? 'Opslaan' : 'Aanmaken' }}</b-button>
            </b-col>
            <p>{{ notification }}</p>
          </b-row>
        </validation-observer>
      </b-container>
    </div>
  </div>
</template>

<script>
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import datepickerLabel from '@/components/datepicker.js'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'
import NoChildModal from '@/components/modals/NoChildModal.vue'

import { mapActions, mapGetters } from "vuex"

import Cleave from 'vue-cleave-component'
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { numeric } from '@validations'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

export default {
  props: {
    loanType: {
      default: "null",
      type: String
    },
    edit: {
      default: null,
      type: String,
    },
    editing: {
      default: null,
      type: Object,
    }
  },
  components: {
    ValidationProvider, ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    Cleave,
    NoChildModal,
  },
  computed: {
    ...mapGetters({ contracts: "contracts/get" }),
    fixed_payment() {
      let payment_period = this.newLoan.payment_period === "kwartaalijks" ? 3 : this.newLoan.payment_period;
      payment_period = this.newLoan.payment_period === "maandelijks" ? 1 : payment_period;
      payment_period = this.newLoan.payment_period === "jaarlijks" ? 12 : payment_period;
      payment_period = this.newLoan.payment_period === "halfjaarlijks" ? 6 : payment_period;

      if (this.loanType === "AnnuityLoanContract") {
        return this.getAnnuity(this.newLoan.total, this.fromPeriods(this.newLoan.interest, payment_period), this.newLoan.length / payment_period).toFixed(2);
      }
      else if (this.loanType === "RepaymentFreeLoanContract") {
        return this.getRepaymentFree(this.newLoan.total, this.fromPeriods(this.newLoan.interest, payment_period)).toFixed(2);
      }
      else {
        return this.getLinear(this.newLoan.total, this.fromPeriods(this.newLoan.interest, payment_period)).toFixed(2);
        // return (this.newLoan.total * payment_period / this.newLoan.length).toFixed(2);
      }
    },
    maxContractLengthValidationRule() {
      if (this.contractDuration.type == 'Maanden') {
        return "600"
      }
      if (this.contractDuration.type == 'Kwartalen') {
        return "150"
      }
      if (this.contractDuration.type == 'Halfjaren') {
        return "100"
      }
      if (this.contractDuration.type == 'Jaren') {
        return "50"
      }
      return "600"
    },
  },
  data() {
    return {
      notification: null,
      maskOptions: {
        currency: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        },
      },
      spendLoanBefore: {
        type: 'Maanden',
        value: null,
      },
      contractDuration: {
        type: 'Maanden',
        value: null,
      },
      fixedInterestPeriod: {
        type: 'Maanden',
        value: null,
      },
      collapseIDs: [],
      datepickerLabel,
      blurActive: false,
      modalContent: {
        description: {
          GiftContract: "schenkingsovereenkomst",
          AnnuityLoanContract: "annuïteitenlening",
          LinearLoanContract: "lineaire lening",
          RepaymentFreeLoanContract: "aflossingsvrije lening",
        },
      },
      contractGoal: [
        {
          value: null, text: 'Selecteer een optie'
        },
        {
          value: "house",
          text: 'Aanschaf/verbetering woning',
        },
        {
          value: 'mortgage',
          text: 'Aflossen hypotheekschuld',
        },
        {
          value: 'bridgeloan',
          text: 'Tijdelijke overbrugging financiële behoefte',
        },
        {
          value: 'object',
          text: 'Aanschaf van een object',
        },
        {
          value: 'other',
          text: 'Anders',
        },
      ],
      newLoan: {
        type: "",
        receiverPartnerSelected: false,
        start_date: null,
        length: null,
        end_date: "",
        payment_day: 1,
        payment_period: 1,
        signed: null,
        total: null,
        interest: null,
        senders: [],
        receivers: [],
        data: {
          monthsToSpend: null,
          mortgageInterestDeduction: false,
          otherLoansOnHouse: false,
          mortgageRights: false,
          placeOfSignature: "",
          dateOfSignature: "",
          dateStartContract: new Date().toISOString().slice(0, 10),
          goal: {
            type: null,
            value: {
              house: {
                street: "",
                housenumber: "",
                postcode: "",
                city: "",
                country: "Nederland",
              },
              bankName: '',
              objectDescription: '',
              otherDescription: '',
            },
          },
          fixedInterestPeriod: null,
          installmentAmount: "",
          installmentPeriod: "maandelijks",
          closingCommission: 0,
          addressKnown: false,
        }
      },
      familyMemberSelected: null,
      familyMembers: [],

      //validation
      numeric,
    }
  },
  mounted() {
    // let _this = this;
    this.familyMembers = this.$auth.db.family.members;

    this.calcEndDate()

    // When a contract is stored in editing you go on where you last where

    if (this.editing && this.edit === null) {
      console.log("editing", this.editing)
      let temp_contract = this.editing
      this.contractDuration.value = temp_contract.length
      this.spendLoanBefore.value = temp_contract.data.monthsToSpend
      this.fixedInterestPeriod.value = temp_contract.data.fixedInterestPeriod
      if (temp_contract.receivers.length > 0) {
        this.familyMemberSelected = this.familyMembers.find(familyMember => {
          return familyMember.hashid === temp_contract.receivers[0].hashid
        })
        if (this.familyMemberSelected) {
          if (!this.newLoan.receivers.find(ele => ele.hashid === this.familyMemberSelected.hashid)) {
            this.newLoan.receivers.push(this.familyMemberSelected);
          }
        }
      }
      if (this.familyMemberSelected && temp_contract.receivers[1]) {
        this.newLoan.receiverPartnerSelected = true
      } else {
        this.newLoan.receiverPartnerSelected = false
      }
      this.newLoan = this.editing
      this.newLoan.type = this.loanType;
      return
    }

    //Otherwise it is a new or an existing contract...
    this.newLoan.type = this.loanType;

    //get current user
    // _this.newLoan.senders.push(this.$auth.db);

    if (this.edit !== null) {
      this.newLoan.hashid = this.edit;

      let temp_contract = this.contracts.find(item => item.hashid == this.edit);
      this.newLoan.start_date = temp_contract.start_date
      this.newLoan.data = temp_contract.data
      this.contractDuration.value = temp_contract.contract.length
      this.newLoan.total = temp_contract.total
      this.newLoan.interest = temp_contract.contract.interests[0].interest
      this.spendLoanBefore.value = temp_contract.data.monthsToSpend
      this.fixedInterestPeriod.value = temp_contract.data.fixedInterestPeriod
      this.familyMemberSelected = this.familyMembers.find(familyMember => {
        return familyMember.hashid === temp_contract.receivers[0].hashid
      })
      if (this.familyMemberSelected) {
        this.newLoan.receivers.push(this.familyMemberSelected);
      }
      if (this.familyMemberSelected && temp_contract.receivers[1]) {
        this.newLoan.receiverPartnerSelected = true
      } else {
        this.newLoan.receiverPartnerSelected = false
      }
      this.newLoan.senders = temp_contract.senders
    }

  },
  methods: {
    ...mapActions({ saveContract: "contracts/add", getPreviewContract: "contracts/preview" }),

    previewContract() {
      let data = JSON.parse(JSON.stringify(this.newLoan));

      data.senders = this.newLoan.senders.map(e => e.hashid);
      data.receivers = this.newLoan.receivers.map(e => e.hashid);

      this.getPreviewContract(data);
    },
    displayErrors(errors) {
      let listOfErrors = []
      for (const error in errors) {
        if (errors[error].length > 0) {
          // Strip the "is verplicht" part of the error
          let nameField = errors[error][0]
          listOfErrors.push(nameField)
        }
      }
      console.log("listOfErrors: ", listOfErrors)
      return listOfErrors
    },
    getFormErrors() {
      let self = this
      this.$nextTick(() => {
        console.log("errors", self.$refs.newLoan.errors) // returns undefined ???
      });
    },
    getLinear(principal, interestPerPeriod) {
      return (principal * interestPerPeriod);
    },
    getAnnuity(total, interest, period) {
      return (total * ((interest) / (1 - Math.pow(1 + interest, -1 * period))));
    },
    getRepaymentFree(principal, interest) {
      // For a repayment-free loan, the payment is just the interest
      const periodInterest = principal * interest;

      return periodInterest;
    },
    fromPeriods(yearly, p) {
      return (yearly / 100) / (12 / p);
      // return (Math.pow((1 + yearly / 100), (1 / (12 / p))) - 1);
    },
    updateSenderList(value) {
      if (!value) {
        this.newLoan.senders = []
      }
      if (this.newLoan.senders.length > 1) {
        this.newLoan.senders.pop()
      }
    },
    setChildInnewContract(value) {
      if (!value) {
        this.newLoan.receivers = []
        this.newLoan.receiverPartnerSelected = null
      }
      console.log("setChildInnewContract", value)
      this.newLoan.receivers = []
      this.newLoan.receivers.push(value);
      this.newLoan.receiverPartnerSelected = null
    },
    addPartner() {
      if (this.newLoan.senders.length == 1) {
        console.log("partners", this.newLoan.senders[0]);
        this.newLoan.senders.push(this.newLoan.senders[0].partners[0].partner);
      }
    },
    deletePartner() {
      this.newLoan.senders.splice(1, 2);
    },
    sendContract() {
      this.saveLoan = JSON.parse(JSON.stringify(this.newLoan));
      this.saveLoan.senders = this.newLoan.senders.map(e => e.hashid);
      this.saveLoan.receivers = this.newLoan.receivers.map(e => e.hashid);
      if (this.edit) {
        this.saveLoan.hashid = this.edit;
        this.saveLoan._method = "PATCH";
      }
      this.saveContract(this.saveLoan).then(() => {
        this.$bvModal.hide("new-loan");
        this.$emit('contractChanged', null);
      });
    },
    updateContract() {
      let temp_contract = this.contractToBeEdited;
      console.log("temp contract: ", temp_contract)
      this.newLoan.start_date = this.contractToBeEdited.start_date
      this.newLoan.data = this.contractToBeEdited.data
      this.contractDuration.value = this.contractToBeEdited.contract.length
      this.newLoan.total = this.contractToBeEdited.total
      this.newLoan.interest = this.contractToBeEdited.contract.interests[0].interest
      this.spendLoanBefore.value = this.contractToBeEdited.data.monthsToSpend
      this.fixedInterestPeriod.value = this.contractToBeEdited.data.fixedInterestPeriod
      this.familyMemberSelected = this.familyMembers.find(familyMember => {
        return familyMember.hashid === this.contractToBeEdited.receivers[0].hashid
      })
      if (this.familyMemberSelected) {
        this.newLoan.receivers.push(this.familyMemberSelected);
      }
      if (this.familyMemberSelected && this.contractToBeEdited.receivers[1]) {
        this.newLoan.receiverPartnerSelected = true
      } else {
        this.newLoan.receiverPartnerSelected = false
      }
      if (this.contractToBeEdited.senders.length > 0) {
        this.newLoan.senders = this.contractToBeEdited.senders
      }
    },
    calcEndDate() {
      let date = new Date(this.newLoan.start_date)
      this.newLoan.end_date = new Date(date.setMonth(date.getMonth() + this.newLoan.length));
    },
    getCollapseID(id) {
      this.collapseIDs.push(id)
    },
    durationToMonths(object) {
      if (object.type == 'Maanden') {
        return object.value
      }
      if (object.type == 'Kwartalen') {
        return object.value * 4
      }
      if (object.type == 'Halfjaren') {
        return object.value * 6
      }
      if (object.type == 'Jaren') {
        return object.value * 12
      }
    },
  },
  watch: {
    newLoan: {
      deep: true,
      handler() {
        if (!this.edit) {
          this.$emit('contractChanged', this.newLoan)
        }
      }
    },
    'newLoan.start_date': function () {
      this.calcEndDate()
    },
    'newLoan.length': function () {
      this.calcEndDate()
    },
    'newLoan.installmentPeriod': function () {
      this.newLoan.payment_period = this.newLoan.installmentPeriod === "kwartaalijks" ? 3 : 1;
      this.newLoan.payment_period = this.newLoan.installmentPeriod === "maandelijks" ? 1 : this.newLoan.payment_period;
      this.newLoan.payment_period = this.newLoan.installmentPeriod === "jaarlijks" ? 12 : this.newLoan.payment_period;
      this.newLoan.payment_period = this.newLoan.installmentPeriod === "halfjaarlijks" ? 6 : this.newLoan.payment_period;
    },
    'newLoan.receiverPartnerSelected': function (newVal) {
      if (newVal === true) {
        if (this.newLoan.receivers.length === 1) {
          this.newLoan.receivers.push(this.familyMemberSelected.partners[0].partner)
        }
      }

      else if (newVal === false) {
        this.newLoan.receivers.splice(1)
      }
    },
    contractDuration: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.newLoan.length = this.durationToMonths(newVal)
      },
    },
    spendLoanBefore: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.newLoan.data.monthsToSpend = this.durationToMonths(newVal)
      },
    },
    fixedInterestPeriod: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.newLoan.data.fixedInterestPeriod = this.durationToMonths(newVal)
      },
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


<style lang="scss" scoped>
.search-people {
  height: 35px;
  width: 35px;
  background-color: rgb(128, 187, 219);
  border: 2px solid white;
  color: white;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
}

.search-people:hover {
  background-color: #0077B6;
}

.mortgage-rights-notification {
  background-color: rgb(239, 239, 239);
  margin-top: 3px;
  padding: 10px;
  border-radius: 5px;
}
</style>

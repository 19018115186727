<template>
  <div>
    <div v-if="$auth.db.children.length == 0">
      <NoChildModal />
    </div>

    <div v-else>
      <p class="text-center">Je hebt gekozen voor een schenking. Door het onderstaande formulier in te vullen, stel je je schenking op. Je kunt de schenking bekijken door op de 'preview' knop te drukken. Wanneer je alles hebt ingevuld, druk dan op 'aanmaken'; wij genereren dan het contract en je kunt daarna het contract downloaden.</p>

      <b-container class="mt-3">
        <h3>Wie is de schenker?</h3>
        <b-row>
          <b-col cols="8">
            <!-- in the vue select, we use the 'transition' prop. Without this set, the modal will close if you select an item that is outside of the modal (for reference: https://github.com/sagalbot/vue-select/issues/1244 ) -->
            <label>Schenker(s)</label>
            <validation-provider #default="{ errors }" name="ontvanger" rules="required">
              <v-select label="firstname" v-model="newGift.senders[0]" :options="familyMembers" @input="updateSenderList" transition="">
                <template slot="selected-option" slot-scope="familyMember">
                  <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                  <span class="ml-1">{{ familyMember.fullname }}</span>
                </template>

                <template v-slot:option="familyMember">
                  <div class="d-flex align-items-center">
                    <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                    <span class="ml-1">{{ familyMember.fullname }}</span>
                  </div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <div class="pl-1 pt-1">
              <b-avatar-group>
                <b-avatar v-b-tooltip.hover v-if="newGift.senders.length > 1" :title="`${newGift.senders[1].firstname} ${newGift.senders[1].lastname}`" class="pull-up" :src="newGift.senders[1].image" :text="!newGift.senders[1].image ? newGift.senders[1].initials : null"></b-avatar> <span v-if="newGift.senders.length > 1" class="pl-1 align-content-center">{{ `${newGift.senders[1].fullname}
                  ` }}</span>
              </b-avatar-group>
            </div>
          </b-col>
          <b-col cols="4">
            <b-button v-if="newGift.senders.length == 1 && newGift.senders[0].partners.length > 0" class="ml-2 mt-2" variant="primary" @click="addPartner()">De partner tekent ook</b-button>
            <b-button v-if="newGift.senders.length == 2" class="ml-2 mt-2" variant="primary" @click="deletePartner()">Partner verwijderen</b-button>
          </b-col>
        </b-row>

        <validation-observer ref="newGift" v-slot="{ handleSubmit }">
          <h3 class="mt-1">Wie is de begunstigde?</h3>
          <b-row>
            <b-col cols="8">
              <!-- in the vue select, we use the 'transition' prop. Without this set, the modal will close if you select an item that is outside of the modal (for reference: https://github.com/sagalbot/vue-select/issues/1244 ) -->
              <label>Begunstigde</label>
              <validation-provider #default="{ errors }" name="ontvanger" rules="required">
                <v-select label="firstname" v-model="receiver" :options="familyMembers" @input="setChildInnewContract" transition="">

                  <template slot="selected-option" slot-scope="familyMember">
                    <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                    <span class="ml-1">{{ familyMember.fullname }}</span>
                  </template>

                  <template v-slot:option="familyMember">
                    <div class="d-flex align-items-center">
                      <b-avatar v-b-tooltip.hover :title="familyMember.fullname" class="pull-up" :src="familyMember.image" :text="!familyMember.image ? familyMember.initials : null"></b-avatar>
                      <span class="ml-1">{{ familyMember.fullname }}</span>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="4" v-if="!receiver" class="d-flex align-items-end">
              <b-button @click="$router.push({ path: 'familiebeheer/relaties' })">Voeg extra familielid toe</b-button>
            </b-col>
            <b-col cols="4" v-if="receiver && receiver.partners.length > 0">
              <label>Tekent {{ receiver.partners[0].partner.firstname }} ook mee?</label>
              <b-form-checkbox v-model="newGift.receiverPartnerSelected">
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- <b-col class="align-self-end">
              <label class="d-flex">
                Is de schenking bedoelt voor de aanschaf van een woning?
              </label>
              <validation-provider
                  #default="{ errors }"
                  name="Doel schenking"
                  rules="required"
                >
                <b-input-group >
                  <b-form-select v-model="newGift.data.goal" :options="optionsGoal"></b-form-select>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <label class="d-flex">
                Op welke datum gaan jullie de schenking aan?
              </label>
              <validation-provider #default="{ errors }" name="begin datum" rules="required">
                <b-form-datepicker locale="nl" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="newGift.start_date" v-bind="datepickerLabel"></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col class="align-self-end">
              <label class="d-flex">
                Hoeveel euro ga je schenken?
              </label>
              <validation-provider #default="{ errors }" name="bedrag schenking" rules="required|min_value:0|max_value:100000000|numeric">
                <b-input-group prepend="€">
                  <cleave class="form-control" v-model.number="newGift.total" :options="maskOptions.currency" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-1 align-items-end">
            <b-col>
              <label class="d">
                Van welke fiscale vrijstelling willen jullie gebruik maken?
              </label>
              <validation-provider #default="{ errors }" name="Doel van de schenking" rules="required">
                <b-input-group>
                  <b-form-select v-model="newGift.data.typeOfExemption" :options="newGift.data.goal === 'house' ? optionsTypeOfExemption.house : optionsTypeOfExemption.general">
                  </b-form-select>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col>
              <label class="d-flex">
                Hoe wens je het bedrag beschikbaar te maken?
              </label>
              <validation-provider #default="{ errors }" name="type betaling" rules="required">
                <b-input-group>
                  <b-form-select :options="optionsPayment" v-model="newGift.data.paymentType" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row v-if="newGift.data.paymentType === 'transfer'" class="mt-1">
            <b-col>
              <label class="d-flex">
                Op welk IBAN nummer ga je de schenking storten?
              </label>

              <!-- Dutch IBAN's have 18 characters, international IBANs can have up to 34 characters -->
              <validation-provider #default="{ errors }" name="bankrekeningnummer" rules="required|min:18|max:34">
                <b-input-group>
                  <cleave class="form-control" :options="maskOptions.iban" placeholder="NL 98 FANK 0123 4567 89" v-model="newGift.data.iban" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col>
              <label class="d-flex">
                De ten naam stelling van de ontvanger
              </label>
              <validation-provider #default="{ errors }" name="Ten naam stelling" rules="required">
                <b-input-group>
                  <b-form-input placeholder="V. Achternaam" v-model="newGift.data.name" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-1">

            <b-col>
              <label class="">
                Wat is het vrijstellingsbedrag?
              </label>
              <validation-provider #default="{ errors }" name="vrijstelling" rules="required">
                <b-form-select v-model.number="newGift.data.exemption" :options="optionsExemptions">
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col class="mb-1">
              <label class="d-flex">
                Wanneer dient het bedrag uitbetaald te zijn?
              </label>
              <validation-provider #default="{ errors }" name="datum uitbetaling" rules="required">
                <b-form-datepicker locale="nl" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="newGift.data.paymentDate" v-bind="datepickerLabel"></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col class="mb-1">
              <label class="d-flex">
                In hoeveel termijnen wilt je de schenking betalen?
              </label>
              <validation-provider #default="{ errors }" name="aantal termijnen van betaling" rules="required|numeric|min_value:1">

                <b-input-group>
                  <b-form-input type="text" v-model.number="newGift.data.terms" :state="errors.length > 0 ? false : null" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col class="mb-1">
              <label class="d-flex">
                Welke ontbindende voorwaarden wil je van toepassing maken?
              </label>
              <validation-provider #default="{ errors }" name="ontbindende voorwaarden" rules="required">

                <b-input-group>
                  <b-form-select :options="newGift.data.goal === 'house' ? optionsCancellation.house : optionsCancellation.general" v-model.number="newGift.data.cancellationClause" :state="errors.length > 0 ? false : null" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-1" v-if="newGift.data.cancellationClause === 'general'">
            <b-col class="mb-1">
              <label class="d-flex">
                Waarvoor wordt de schenking gebruikt? Maak de volgende zin af "De begunstigde dient de middelen te gebruiken voor ..."
              </label>
              <validation-provider #default="{ errors }" name="Gebruik schenking" rules="required">

                <b-input-group>
                  <b-form-input type="text" placeholder="de aanschaf van een nieuwe keuken" v-model.number="newGift.data.giftUsedFor" :state="errors.length > 0 ? false : null" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col class="mb-1">
              <label class="d-flex">
                Plaats van ondertekening
              </label>
              <validation-provider #default="{ errors }" name="plaats van ondertekening" rules="required">
                <b-input-group>
                  <b-form-input type="text" placeholder="Plaats" v-model="newGift.data.placeOfSignature" :state="errors.length > 0 ? false : null" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col class="mb-1" cols="12" md="6">
              <label class="d-flex">
                Datum van ondertekening
              </label>
              <validation-provider #default="{ errors }" name="begin datum" rules="required">
                <b-form-datepicker locale="nl" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="newGift.data.dateOfSignature" v-bind="datepickerLabel"></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="text-center">
            <b-col class="text-center">
              <b-button variant="primary m-1" @click="handleSubmit(previewContract)">Bekijken</b-button>
              <b-button variant="success m-1" @click="handleSubmit(saveGift)">{{ edit ? 'Opslaan' : 'Aanmaken' }}</b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
    </div>
  </div>
</template>

<script>
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import datepickerLabel from '@/components/datepicker.js'
import NoChildModal from '@/components/modals/NoChildModal.vue'

import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import Cleave from 'vue-cleave-component'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

import { mapGetters, mapActions } from "vuex"

export default {
  props: {
    loanType: {},
    id: {
      type: String,
      default: "new-gift"
    },
    edit: {
      default: null,
      type: String,
    },
    editing: {
      default: null,
      type: Object,
    }
  },
  components: {
    ValidationProvider, ValidationObserver,
    Cleave,
    NoChildModal,
  },
  data() {
    return {
      maskOptions: {
        iban: {
          blocks: [2, 2, 4, 4, 4, 2],
          uppercase: true,
        },
        currency: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        },
      },
      datepickerLabel,
      partnerAdded: false,
      optionsExemptions: [
      ],
      optionsTypeOfExemption: {
        house: [
          { value: null, text: 'Selecteer een optie' },
          {
            value: "general",
            text: 'De algemene vrijstelling voor schenkingen tussen ouder en kind.',
          },
          {
            value: "money",
            text: 'De verruimde fiscale vrijstelling voor schenking van geld.',
          },
          {
            value: "house",
            text: 'De verruimde fiscale vrijstelling voor schenking van geld voor de Eigen Woning.',
          },
        ],
        general: [
          { value: null, text: 'Selecteer een optie' },
          {
            value: "general",
            text: 'De algemene vrijstelling voor schenkingen tussen ouder en kind.',
          },
          {
            value: "money",
            text: 'De verruimde fiscale vrijstelling voor schenking van geld.',
          },
        ]
      },
      optionsGoal: [
        {
          value: "house",
          text: 'Ja',
        },
        {
          value: "other",
          text: "Nee"
        }

      ],
      optionsPayment: [
        {
          value: "transfer",
          text: 'Overboeken',
        },
        {
          value: "remission",
          text: "Kwijtschelding op een schuld"
        },
        {
          value: "cash",
          text: 'Contant',
        },
      ],
      optionsCancellation: {
        house: [
          {
            value: "house",
            text: 'Ontbindende voorwaarden voor een woning',
          },
          {
            value: "nothing",
            text: 'Geen ontbindende voorwaarden',
          },
        ],
        general: [
          {
            value: "general",
            text: 'Ontbindende voorwaarden - algemeen',
          },
          {
            value: "nothing",
            text: 'Geen ontbindende voorwaarden',
          },
        ],
      },
      newGift: {
        type: "GiftContract",
        start_date: new Date().toISOString().slice(0, 10),
        total: null,
        receiverPartnerSelected: null,
        data: {
          goal: "other",
          typeOfExemption: "",
          exemption: "",
          paymentType: "",
          iban: "",
          name: "",
          paymentDate: "",
          terms: null,
          cancellationClause: "",
          giftUsedFor: "",
          placeOfSignature: "",
          dateOfSignature: "",
        },
        senders: [],
        receivers: [],
      },
      receiver: "",
      accountHolder: {},
      familyMembers: [],
    }
  },
  mounted() {
    let _this = this;
    console.log(_this.newGift.senders);
    _this.familyMembers = _this.$auth.db.family.members

    this.getExemptions()

    // When a contract is stored in editing you go on where you last where
    if (this.editing && this.edit === null) {
      let temp_contract = this.editing
      this.newGift.start_date = temp_contract.start_date
      this.newGift.data = temp_contract.data
      this.newGift.total = temp_contract.total
      if (temp_contract.receivers.length > 0) {
        this.receiver = this.familyMembers.find(familyMember => {
          return familyMember.hashid === temp_contract.receivers[0].hashid
        })
        if (this.receiver) {
          if (!this.newGift.receivers.find(ele => ele.hashid === this.receiver.hashid)) {
            this.newGift.receivers.push(this.receiver);
          }
        }
      }
      if (this.receiver && temp_contract.receivers[1]) {
        this.newGift.receiverPartnerSelected = true
      } else {
        this.newGift.receiverPartnerSelected = false
      }
      if (temp_contract.senders.length > 0) {
        this.newGift.senders = temp_contract.senders
      }

      // this.newGift.senders.push(this.$auth.db);
      return true;
    }

    //Otherwise it is a new or an existing contract...

    //get current user
    // _this.newGift.senders.push(this.$auth.db);

    if (this.edit !== null) {
      this.newGift.hashid = this.edit;

      let temp_contract = this.contracts.find(item => item.hashid == this.edit);
      this.newGift.start_date = temp_contract.start_date
      this.newGift.data = temp_contract.data
      this.newGift.total = temp_contract.total
      this.receiver = this.familyMembers.find(familyMember => {
        return familyMember.hashid === temp_contract.receivers[0].hashid
      })
      if (this.receiver) {
        this.newGift.receivers.push(this.receiver);
      }
      if (this.receiver && temp_contract.receivers[1]) {
        this.newGift.receiverPartnerSelected = true
      } else {
        this.newGift.receiverPartnerSelected = false
      }
      if (temp_contract.senders.length > 0) {
        this.newGift.senders = temp_contract.senders
      }
    }
  },
  methods: {
    ...mapActions({ saveContract: "contracts/add", getPreviewContract: "contracts/preview" }),

    previewContract() {
      let data = JSON.parse(JSON.stringify(this.newGift));
      delete data._method;

      data.senders = this.newGift.senders.map(e => e.hashid);
      data.receivers = this.newGift.receivers.map(e => e.hashid);
      console.log(data);
      this.getPreviewContract(data);
    },
    deletePartner() {
      this.newGift.senders.splice(1, 2);
    },
    updateSenderList(value) {
      if (!value) {
        this.newGift.senders = []
      }
      if (this.newGift.senders.length > 1) {
        this.newGift.senders.pop()
      }
    },
    getExemptions() {
      this.$http.get("/settings/exemptions").then(response => {
        if (response.data.value) {
          JSON.parse(response.data.value).forEach((obj) => {
            let amount = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(obj.amount)
            let description = obj.description
            this.optionsExemptions.push({ text: amount + " - " + description, value: obj.amount })
          })
        }
      })
    },
    addPartner() {
      if (this.newGift.senders.length === 1) {
        this.newGift.senders.push(this.newGift.senders[0].partners[0].partner);
      }
    },
    setChildInnewContract(value) {
      if (!value) {
        this.newGift.receivers = [];
      }
      this.newGift.receivers = [];
      this.newGift.receivers.push(value);
      this.newGift.receiverPartnerSelected = null
    },
    saveGift() {
      this.save_gift = JSON.parse(JSON.stringify(this.newGift));
      this.save_gift.senders = this.save_gift.senders.map(e => e.hashid);
      this.save_gift.receivers = this.save_gift.receivers.map(e => e.hashid);
      if (this.edit) {
        this.save_gift.hashid = this.edit;
        this.save_gift._method = "PATCH";
      }
      this.saveContract(this.save_gift).then(() => {
        this.$bvModal.hide("new-gift");
        this.$emit('contractChanged', null);
      });
    }
  },
  computed: {
    ...mapGetters({ contracts: "contracts/get" }),
  },
  watch: {
    newGift: {
      deep: true,
      handler() {
        if (!this.edit) {
          this.$emit('contractChanged', this.newGift)
        }
      }
    },
    'newGift.receiverPartnerSelected': function (newVal) {
      if (newVal === true) {
        if (this.newGift.receivers.length === 1) {
          this.newGift.receivers.push(this.receiver.partners[0].partner)
        }
      }

      else if (newVal === false) {
        this.newGift.receivers.splice(1)
      }
    },
  },
  // created() {
  //   let accountHolderWithoutPartner = Object.assign({}, this.accountHolder)
  //   delete accountHolderWithoutPartner.partner
  //   this.donors.accountHolder = accountHolderWithoutPartner
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


<style lang="scss" scoped>
.search-people {
  height: 35px;
  width: 35px;
  background-color: rgb(128, 187, 219);
  border: 2px solid white;
  color: white;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
}

.search-people:hover {
  background-color: #0077B6;
}
</style>
